<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- <c-btn
                  v-if="editable && popupParam.envAirDailyResultId"
                  label="일지출력"
                  color="amber-7"
                  @btnClicked="print" 
                  icon="print" /> -->
                <c-btn
                  v-if="editable && popupParam.envAirDailyResultId"
                  label="전체화면 보기"
                  @btnClicked="fullScreenReport" 
                  icon="receipt_long" />
                <!-- <c-btn
                  v-if="editable && updateMode"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteData" /> -->
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                  :required="true"
                  :editable="false"
                  label="등록일"
                  name="dailyResultDt"
                  v-model="data.dailyResultDt"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-field
                  :editable="false"
                  :data="data"
                  deptValue="deptCd"
                  type="dept_user"
                  label="작성부서 & 작성자"
                  name="userId"
                  v-model="data.userId">
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-plant :required="true" :editable="false" type="edit" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-select
                  :editable="editable"
                  codeGroupCd="WEATHER_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="weatherCd"
                  label="날씨"
                  v-model="data.weatherCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="온도"
                  suffix="℃"
                  name="temperature"
                  v-model="data.temperature">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical" v-if="popupParam.envAirDailyResultId">
          <c-tab
            type="vertical"
            :tabItems.sync="tabItems"
            :inlineLabel="true"
            v-model="tab"
            :dense="true"
            align="left"
            :height="tabHeight"
            :splitter="splitter"
            tabClassVertical="leftTabAlign"
          >
            <template v-slot:default="tab">
              <component
                :is="tab.component"
                :param.sync="popupParam"
              />
            </template>
          </c-tab>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-air-report-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          envAirDailyResultId: '',
          isFullScreen: false,
          plantCd: null,
          dailyResultDt: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      splitter: { data: 185 },
      tab: 'tab1',
      tabItems: [],
      data: {
        plantCd: null,  // 사업장코드
        envAirDailyResultId: '',  // 대기일지 일련번호
        dailyResultDt: '',  // 작성일
        deptCd: '',  // 작성부서
        userId: '',  // 작성자
        weatherCd: null,  // 날씨
        temperature: '',  // 온도
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
      saveUrl: transactionConfig.env.air.daily.result.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      detailUrl: '',
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.air.daily.result.get.url;
      this.saveUrl = transactionConfig.env.air.daily.result.insert.url;
      this.deleteUrl = transactionConfig.env.air.daily.result.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.envAirDailyResultId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envAirDailyResultId: this.popupParam.envAirDailyResultId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.popupParam.plantCd = this.data.plantCd;
          this.popupParam.dailyResultDt = this.data.dailyResultDt;
          this.popupParam.approvalTarget = true;
          this.tabItems = [
            { name: 'tab1', label: '1. 배출구별 가동(조업)시간', component: () => import(`${'@/pages/env/air/dailyAirReport01.vue'}`) },
            { name: 'tab2', label: '2. 방지시설 운전사항', component: () => import(`${'@/pages/env/air/dailyAirReport02.vue'}`) },
            { name: 'tab3', label: '3. 방지시설 보수내역', component: () => import(`${'@/pages/env/air/dailyAirReport03.vue'}`) },
            { name: 'tab4', label: '4. 연료사용량', component: () => import(`${'@/pages/env/air/dailyAirReport04.vue'}`) },
            { name: 'tab5', label: '5. 원료사용량', component: () => import(`${'@/pages/env/air/dailyAirReport05.vue'}`) },
          ]
        },);
      } else {
        this.data.userId = this.$store.getters.user.userId;
        this.data.deptCd = this.$store.getters.user.deptCd;
      }
    },
    saveInfo() {
      if (this.popupParam.envAirDailyResultId) {
        this.saveUrl = transactionConfig.env.air.daily.result.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.env.air.daily.result.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.envAirDailyResultId = result.data.envAirDailyResultId;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '일지전체 내용이 삭제됩니다.\r\n삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.envAirDailyResultId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    fullScreenReport() {
      this.popupOptions.target = () => import(`${"@/pages/env/air/dailyAirReportFullScreen.vue"}`);
      this.popupOptions.title = '대기일지 전체';
      this.popupOptions.param = {
        envAirDailyResultId: this.popupParam.envAirDailyResultId,
        plantCd: this.popupParam.plantCd,
        isFullScreen: true,
        dailyResultDt: this.data.dailyResultDt,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    print() {
      alert();
      window.open('/대기일지_샘플.xlsx');
    }
  }
};
</script>